import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserMyPage = () => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    // 使用假设的用户信息数据
    const mockUserInfo = {
      name: '张三',
      phoneNumber: '123-456-7890',
    };

    setUserInfo(mockUserInfo);
  }, []);

  return (
    <div className="container mt-6">
      <h1 className="text-center mb-5">我的信息</h1>
      <div className="card mb-3" style={{ maxWidth: '540px', margin: '0 auto' }}>
        <div className="card-body">
          <h5 className="card-title">姓名</h5>
          <p className="card-text">{userInfo.name}</p>
          <h5 className="card-title">电话号码</h5>
          <p className="card-text">{userInfo.phoneNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default UserMyPage;