import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserHistoryPage = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    // 使用假设的预约历史数据
    const mockAppointments = [
      {
        id: 1,
        roomName: '科研楼404',
        appointmentTime: '2023-10-12 10:00-12:00',
        status: '已完成',
      },
      {
        id: 2,
        roomName: '主楼404',
        appointmentTime: '2023-10-13 14:00-16:00',
        status: '未签到',
      },
      {
        id: 3,
        roomName: '科研楼404',
        appointmentTime: '2023-10-14 09:00-11:00',
        status: '已取消',
      },
    ];

    setAppointments(mockAppointments);
  }, []);

  const handleCancelAppointment = (id) => {
    // 在这里处理取消预约的逻辑，例如发送请求到后端
    // 为了演示，我们直接更新状态
    setAppointments((prevAppointments) =>
      prevAppointments.map((appointment) =>
        appointment.id === id
          ? { ...appointment, status: '已取消' }
          : appointment
      )
    );
    alert(`预约已取消: ${id}`);
  };

  return (
    <div className="container mt-6">
      <h1 className="text-center mb-5">预约历史</h1>
      <ul className="list-group">
        {appointments.map((appointment) => (
          <li key={appointment.id} className="list-group-item" style={{ border: 'none', padding: '1rem' }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-1">{appointment.roomName}</h5>
                <small>时间: {appointment.appointmentTime}</small>
                <div className="text-muted small mb-1">状态: {appointment.status}</div>
              </div>
              {appointment.status === '未签到' && (
                <button
                  onClick={() => handleCancelAppointment(appointment.id)}
                  className="btn btn-danger"
                >
                  取消
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserHistoryPage;