import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/UserBookDay.css'

// 假设的可预约时段数据
const mockTimeslots = [
  { id: 1, time: '09:00 - 10:00', available: true },
  { id: 2, time: '10:00 - 11:00', available: false },
  { id: 3, time: '11:00 - 12:00', available: true },
  { id: 4, time: '12:00 - 13:00', available: true },
  { id: 5, time: '13:00 - 14:00', available: true },
  { id: 6, time: '14:00 - 15:00', available: true },
  { id: 7, time: '15:00 - 16:00', available: false },
  { id: 8, time: '16:00 - 17:00', available: true },
  // ...更多时段
];

const UserBookDay = ({ date, onTimeslotClick, roomName }) => {
    return (
      <div >
            <h3>可预约时段 - {date}</h3>
        <ul className="list-group scrollable-timeslots">
          {mockTimeslots.map((timeslot) => (
            <li key={timeslot.id} className="list-group-item timeslot-item">
              <button
                className={`btn btn-${timeslot.available ? 'success' : 'danger'} btn-block`}
                onClick={() => onTimeslotClick(roomName, timeslot.time)}
                disabled={!timeslot.available}
              >
                {timeslot.time} {timeslot.available ? '可预约' : '已预约'}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default UserBookDay;