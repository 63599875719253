import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/UserBookPage.css'; // 自定义样式，用于调整横向滚动条等
import UserBookDay from './UserBookDay'; // 稍后实现这个组件

const UserBookPage = () => {
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const { roomName } = useParams();

    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const handleTimeslotClick = (roomName, time) => {
        setModalContent({ roomName, time });
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalConfirm = () => {
        // 在这里处理预约逻辑
        alert(`已预约会议室: ${modalContent.roomName}, 时间: ${modalContent.time}`);
        setShowModal(false);
    };

  // 生成未来7天的日期数组
  const generateDates = () => {
    const today = new Date();
    const datesArray = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      const formattedDate = date.toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      datesArray.push(formattedDate);
    }
      setDates(datesArray);
      
      if (datesArray.length > 0) {
        setSelectedDate(datesArray[0]);
      }
  };

  // 在组件加载时生成日期
  React.useEffect(() => {
    generateDates();
  }, []);


  return (
    <div className="container mt-6">
          <h1 className="text-center mb-5">{ roomName }</h1>

      {/* 日期选择框 */}
      <div className="date-scroll-container">
        {dates.map((date) => (
          <button
            key={date}
            className={`btn btn-${selectedDate === date ? 'primary' : 'secondary'} mx-1`}
            onClick={() => handleDateSelect(date)}
          >
            {date}
          </button>
        ))}
      </div>

      {/* 可预约时段显示区域 */}
      {selectedDate && (
        <div className="mt-5">
          <UserBookDay
            date={selectedDate}
            onTimeslotClick={handleTimeslotClick}
            roomName={roomName} // 传递会议室名称
          />
        </div>
      )}
 
      {/* 弹窗 */}
      {showModal && (
        <div className="modal fade show d-block " tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header modal-header-custom">
                <h3 className="modal-title" id="exampleModalLabel">确认预约</h3>
                </div>
                <div className="modal-body">
                <p>会议室: {modalContent.roomName}</p>
                <p>时间: {modalContent.time}</p>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                <button type="button" className="btn btn-secondary" onClick={handleModalClose}>取消</button>
                <button type="button" className="btn btn-primary" onClick={handleModalConfirm}>确定</button>
                </div>
            </div>
            </div>
        </div>
        )}
    </div>
  );
};

export default UserBookPage;