import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserRoomsPage from './UserRoomsPage';
import UserHistoryPage from './UserHistoryPage';
import UserMyPage from './UserMyPage';

const UserMainPage = () => {
  const [currentPage, setCurrentPage] = useState('预约');

  const pages = {
    '预约': <UserRoomsPage />,
    '预约历史': <UserHistoryPage />,
    '我的': <UserMyPage />,
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="d-flex flex-column vh-100">
      {/* 显示区域，占据大部分空间 */}
      <div className="flex-grow-1 overflow-hidden">
        <div className="p-3 bg-light" style={{ height: '100%', overflowY: 'auto' }}>
          {pages[currentPage]}
        </div>
      </div>
 
      {/* 页面选项区域，占据固定高度 */}
      <div className="d-flex justify-content-around bg-white border-top flex-shrink-0" style={{ height: '56px' }}>
        <button
          className={`btn btn-block ${currentPage === '预约' ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => handlePageChange('预约')}
                  style={{width: '33%'}}
        >
          预约
        </button>
        <button
          className={`btn btn-block ${currentPage === '预约历史' ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => handlePageChange('预约历史')}
                  style={{width: '33%'}}
        >
          预约历史
        </button>
        <button
          className={`btn btn-block ${currentPage === '我的' ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => handlePageChange('我的')}
                  style={{width: '33%'}}
        >
          我的
        </button>
      </div>
    </div>
  );
};

export default UserMainPage;