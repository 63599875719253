import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // 注释掉axios的导入，因为我们不再进行实际请求
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate
// import './UserRoomsPage.css'; // 你可以在这里添加一些CSS样式
import 'bootstrap/dist/css/bootstrap.min.css';

const UserRoomsPage = () => {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate(); // 使用 useNavigate 钩子
  // const [loading, setLoading] = useState(true); // 不再需要loading状态，因为我们直接设置数据
  // const [error, setError] = useState(null); // 不再需要error状态，因为没有实际的请求

  useEffect(() => {
    // 注释掉实际的请求代码
    // const fetchRooms = async () => {
    //   try {
    //     const response = await axios.get('https://api.example.com/rooms');
    //     setRooms(response.data);
    //   } catch (err) {
    //     setError(err);
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    
    // 使用假设的会议室数据
    const mockRooms = [
      { id: 1, name: '科研楼404', location: '科研楼4楼' },
      { id: 2, name: '主楼404', location: '主楼4楼' }
    ];
    
    setRooms(mockRooms);
  }, []);

  // 由于我们不再进行加载或错误处理，因此可以删除相关的条件渲染
  return (
    <div className="container mt-6">
      <h1 className="text-center mb-5">可预约的会议室</h1>
      <ul className="list-group">
        {rooms.map((room) => (
          <li key={room.id} className="list-group-item" style={{ border: 'none', padding: '1rem'}}>
            <button 
              onClick={() => navigate(`/book/${room.name}`)} // 修改为导航到 UserBookPage
              className="btn btn-primary btn-block" 
              style={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem', borderRadius: '0.25rem', border: 'none', backgroundColor: '#007bff', color: '#fff', fontWeight: 'bold', fontSize: '1rem', cursor: 'pointer'}}
            >
              <h5 className="mb-1">{room.name}</h5>
              <small>位置: {room.location}</small>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserRoomsPage;